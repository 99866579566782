import React, { useState, useRef } from "react";
import { Button, Dropdown, FormControl } from "react-bootstrap";

const ShareURLDropdown = ({ url, shareProcessing, onClickFunc }) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy URL");
  const urlInputRef = useRef(null);

  const copyToClipboard = () => {
    if (urlInputRef.current) {
      urlInputRef.current.select();
      navigator.clipboard.writeText(url);

      setCopyButtonText("Copied!");
      setTimeout(() => setCopyButtonText("Copy URL"), 1000);
    }
  };

  const handleInputClick = () => {
    if (urlInputRef.current) {
      urlInputRef.current.select();
    }
  };

  let url_out = <></>;
  let copy_button = <></>;

  if (url) {
    url_out = (
      <FormControl
        as="input"
        ref={urlInputRef}
        value={url} // using the prop value
        readOnly
        onClick={handleInputClick}
        style={{ flex: 1, marginRight: "0.5rem" }}
      />
    );
    copy_button = (
      <Button variant="outline-secondary" onClick={copyToClipboard}>
        {copyButtonText}
      </Button>
    );
  } else if (shareProcessing) {
    url_out = <span style={{ marginRight: "10px" }}>{shareProcessing}</span>;
  }

  return (
    <Dropdown
      onClick={(e) => {
        onClickFunc(e);
      }}
    >
      <Dropdown.Toggle
        variant="primary"
        id="share"
        style={{ marginLeft: "20px", marginTop: "4px", marginRight: "4px" }}
      >
        Share
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            width: "300px",
          }}
        >
          {url_out}
          {copy_button}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ShareURLDropdown;
